.app__header {
    background-color: rgba(0, 250, 12, 0.39);
    padding: 2rem; /* Add some padding */
    overflow: hidden; /* Prevent horizontal scrolling */
}

.app__header-h1 {
    font-family: var(--font-base);
    color: red;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    line-height: 117px;
    font-size: 90px;
}

.app__wrapper_img img {
    border-radius: 150px;
    width: 80%;
}

.custom__button {
    background-color: green;
}

@media (max-width: 768px) {
    .app__header-h1 {
        font-size: 35px; /* Adjust font size for mobile */
        line-height: 60px; /* Adjust line height for mobile */
    }

    .p__opensans3 {
        font-size: 16px; /* Adjust paragraph font size for mobile */
    }

    .app__wrapper_img img {
        width: 100%; /* Make the image width 100% for mobile */
    }

    .app__header {
        padding: 1rem; /* Adjust padding for mobile */
    }
}
