/* General Styles */
.app__header {
    background-color: rgba(0, 250, 12, 0.39);
    padding: 2rem; /* Add some padding */
    overflow: hidden; /* Prevent horizontal scrolling */
}

.app__header-h1 {
    font-family: var(--font-base);
    color: red;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    line-height: 117px;
    font-size: 90px;
}

.app__wrapper_img img {
    border-radius: 150px;
    width: 80%;
}

.custom__button {
    background-color: green;
    margin: 0 10px; /* Add margin for spacing */
}

.button-container {
    display: flex;
    justify-content: center; /* Center the buttons */
    margin-top: 20px; /* Add top margin for spacing */
}

/* Modal Styles */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: rgb(52, 95, 196);
    padding: 20px;
    max-width: 500px;
    width: 90%;
    max-height: 80vh; /* Maximum height of the modal */
    overflow-y: auto; /* Enable vertical scrolling */
    border-radius: 8px;
    position: relative;
  
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    color:white;
    border: none;
    font-size: 30px;
    cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .app__header-h1 {
        font-size: 35px; /* Adjust font size for mobile */
        line-height: 60px; /* Adjust line height for mobile */
    }

    .p__opensans3 {
        font-size: 16px; /* Adjust paragraph font size for mobile */
    }

    .app__wrapper_img img {
        width: 100%; /* Make the image width 100% for mobile */
    }

    .app__header {
        padding: 1rem; /* Adjust padding for mobile */
    }

    .custom__button {
        margin: 10px 0; /* Add vertical margin for mobile */
    }

    .button-container {
        flex-direction: column; /* Stack buttons vertically on mobile */
    }
}
