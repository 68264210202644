@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
h1 {
    color: rgb(255, 255, 255)
}
.menu-section {
    margin-bottom: 2em;
}
.menu-section h2 {
    background-color: #0646db;
    color: white;
    padding: 10px;
}
.menu-item {
    margin: 0.5em 0;
}
.menu-item h3 {
    margin: 0;
}
.menu-item p {
    margin: 0.2em 0 0.5em 0;
}
p {
    color: white;
    font-family: "Mulish", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}