.app__footerOverlay {
    width: 100%;
    height: 100%;
    z-index: 100;

    display: flex;
    flex-direction: column;
    position: absolute;
}

.app__footerOverlay-black {
    height: 25%;
    background: var(--color-black);
}

.app__footerOverlay__img {
    height: 75%;
}